import "./post.scss";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link } from "react-router-dom";
import Comments from "../comments/Comments";
import { useState, useContext } from "react";
import moment from "moment";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { Modal, notification } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { useTranslation } from "react-i18next";
import makeLinksClickableWithPreview from "./makeLinksClickableWithPreview";

const Post = ({ post, postKey }) => { 
  const [commentOpen, setCommentOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [saved,setSaved] = useState(post.postSaved);
  const [addReportModalShow,setAddReportModalShow] = useState(false)
  const [postToReport,setPostToReport] = useState()
  const [selectedDisputeId, setSelectedDisputeId] = useState(null);
  const {t} = useTranslation();
  const userId = postKey
  // console.log(postKey)   

  const handleLike = () => {
    makeRequest
      .post(
        `/ssakti/users/postreact/addReactOnPost/${currentUser.id}/${post.postId}`,
        {
          postReactName: "Like",
          postReactImageUrl: "string",
        }
      )
      .then(() => {      
        queryClient.setQueryData(['posts', userId], (oldData) => {
          if (!oldData) return oldData;
          
          const updatedPages = oldData.pages.map((page) => {
            return {
              ...page,                          
              homePostData: page?.homePostData ? page.homePostData.map((h) =>
                h.postId === post.postId
                  ? { 
                      ...h, 
                      totalCountOFReact: h.totalCountOFReact + 1, 
                      userReactStatus: true 
                    }
                  : h
              )
              :undefined,

              postData: page?.postData 
              ? page.postData.map((p) =>
                  p.postId === post.postId
                    ? {
                        ...p,
                        totalCountOFReact: p.totalCountOFReact + 1,
                        userReactStatus: true,
                      }
                    : p
                )
              : undefined,
            };
          });

          console.log(updatedPages)
  
          return {
            ...oldData,
            pages: updatedPages, 
          };
        });

        queryClient.setQueryData(['groupDetails'], (oldData) => {
          if (!oldData) return oldData;          

          const updatedPages = oldData.pages.map((page) => {
            return {
              ...page,                          
              postDetails: page?.postDetails ? page.postDetails.map((h) =>
                h.postId === post.postId
                  ? { 
                      ...h, 
                      totalCountOFReact: h.totalCountOFReact + 1, 
                      userReactStatus: true 
                    }
                  : h
              )
              :undefined,              
            };
          });
  
          return {
            ...oldData,
            pages: updatedPages, 
          };
        });

        queryClient.setQueryData(['pageDetails'], (oldData) => {
          if (!oldData) return oldData;                   

          const updatedPages = oldData.pages.map((page) => {
            return {
              ...page,                          
              postDetails: page?.postDetails ? page.postDetails.map((h) =>
                h.postId === post.postId
                  ? { 
                      ...h, 
                      totalCountOFReact: h.totalCountOFReact + 1, 
                      userReactStatus: true 
                    }
                  : h
              )
              :undefined,              
            };
          });
  
          return {
            ...oldData,
            pages: updatedPages, 
          };
        });

      })
      .catch((error) => {
        console.error("Error liking the post:", error);
      });
  };
 
  const handleRemoveLike = () => {    
    makeRequest
      .delete(
        `/ssakti/users/postreact/deleteReactOnPost/${currentUser.id}/${post.postId}`,
        {
          postReactName: "Like",
        }
      )
      .then(() => {      
        queryClient.setQueryData(['posts', userId], (oldData) => {
          if (!oldData) return oldData;
          
          const updatedPages = oldData.pages.map((page) => {
            return {
              ...page,                          
              homePostData: page?.homePostData ? page.homePostData.map((h) =>
                h.postId === post.postId
                  ? { 
                      ...h, 
                      totalCountOFReact: h.totalCountOFReact - 1, 
                      userReactStatus: false 
                    }
                  : h
              )
              :undefined,

              postData: page?.postData 
              ? page.postData.map((p) =>
                  p.postId === post.postId
                    ? {
                        ...p,
                        totalCountOFReact: p.totalCountOFReact - 1,
                        userReactStatus: false,
                      }
                    : p
                )
              : undefined,
            };
          });

          console.log(updatedPages)
  
          return {
            ...oldData,
            pages: updatedPages, 
          };
        });	

        queryClient.setQueryData(['groupDetails'], (oldData) => {
          if (!oldData) return oldData;          

          const updatedPages = oldData.pages.map((page) => {
            return {
              ...page,                          
              postDetails: page?.postDetails ? page.postDetails.map((h) =>
                h.postId === post.postId
                  ? { 
                      ...h, 
                      totalCountOFReact: h.totalCountOFReact - 1, 
                      userReactStatus: false 
                    }
                  : h
              )
              :undefined,              
            };
          });
  
          return {
            ...oldData,
            pages: updatedPages, 
          };
        });
        
        queryClient.setQueryData(['pageDetails'], (oldData) => {
          if (!oldData) return oldData;                   

          const updatedPages = oldData.pages.map((page) => {
            return {
              ...page,                          
              postDetails: page?.postDetails ? page.postDetails.map((h) =>
                h.postId === post.postId
                  ? { 
                      ...h, 
                      totalCountOFReact: h.totalCountOFReact - 1, 
                      userReactStatus: false 
                    }
                  : h
              )
              :undefined,              
            };
          });
  
          return {
            ...oldData,
            pages: updatedPages, 
          };
        });
		
      })
      .catch((error) => {
        console.error("Error while dis-liking the post:", error);
      });
  };

  const mutation = useMutation((postId) => {    
    const response = makeRequest.delete(`sskati/users/posts/deletPost/${postId}`);      
    if(response){        
      return response.data
    }
    else{
      console.log("error deleting post")
    }
  }, 
  {
    onSuccess: () => { 
      queryClient.setQueryData(['posts', userId], (oldData) => {      
        if (!oldData) return oldData;
        
        const updatedPages = oldData.pages.map((page) => {
          return {
            ...page,
            homePostData: page?.homePostData
              ? page.homePostData.filter((h) => h.postId !== post.postId)
              : undefined,
            postData: page?.postData
              ? page.postData.filter((p) => p.postId !== post.postId)
              : undefined,
          };
        });

        return {
          ...oldData,
          pages: updatedPages,
        };
      });

      queryClient.setQueryData(['groupDetails'], (oldData) => {
        if (!oldData) return oldData;                   

        const updatedPages = oldData.pages.map((page) => {
          return {
            ...page, 
      postDetails: page?.postDetails
      ? page.postDetails.filter((p) => p.postId !== post.postId)
      : undefined,   
          };
        });

        return {
          ...oldData,
          pages: updatedPages, 
        };
      });

      queryClient.setQueryData(['pageDetails'], (oldData) => {
        if (!oldData) return oldData;                   

        const updatedPages = oldData.pages.map((page) => {
          return {
            ...page, 
      postDetails: page?.postDetails
      ? page.postDetails.filter((p) => p.postId !== post.postId)
      : undefined,   
          };
        });

        return {
          ...oldData,
          pages: updatedPages, 
        };
      });  
      
      notification.success({
        message: 'Post has been deleted',
      });
    },
  });

  const handleDelete = (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      mutation.mutate(postId);      
    }
  };

  const handleSavePost=async(postId)=>{
    try {
      const response = await makeRequest.post(`/ssakti/users/savepost/savePost/${currentUser.id}/${postId}`)
      if(response.status === 201){
        console.log("post saved", response)
        setSaved(true)
        notification.success({
          message: response.data.message,
        });
      }
      else{
        notification.error({
          message: response.data.message,
        });
      }
    } catch (error) { 
      console.log("error saving post", error)
      notification.error({
        message: error.response.data.message,
      });
    }
  }

  const handleDelSavedPost=async(postId)=>{
    try{
      console.log(postId)
      const response = await makeRequest.delete(`/ssakti/users/savepost/deletSavedPost/${currentUser.id}/${postId}`)
      console.log(response)
      if(response.status === 200){
        setSaved(false)
        notification.success({
          message: response.data.message,
        });
      }
    }
    catch(error){
      // console.log(err)
      notification.error({
        message: error.response.data.message,
      });
    }
  }

  const renderMedia = () => {
    if (post?.postType?.includes("video")) {
      return (
        <div className="video-post">
        <video controls>
          <source src={post.postImageURl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
      );
    } else if(post?.postType?.includes("image")){
        return <img src={post?.postImageURl.includes("amazon")?"https://strishakti.org/uploads/strishakti/posts/posts"+post.postImageURl.split("/posts")[1] : post?.postImageURl} alt="" />;
    } else{
      if(post?.postType?.includes("link")) {
       return makeLinksClickableWithPreview( post)
    }
      return null
    }
  };
   

  const addReportToPost=async(postId)=>{
    try{
      const response = await makeRequest.post(`/ssakti/users/dispute/raiseDispute/${currentUser.id}/${postToReport}/${selectedDisputeId.id}`,{
        "disputeType": selectedDisputeId.type,
        "disputeDescription": selectedDisputeId.description,
      })
      console.log(response)
      if(response.status === 201){
        notification.success({
          message: response.data.message,
        });
        setAddReportModalShow(false)
        setPostToReport(null)
        setSelectedDisputeId(null)
        queryClient.invalidateQueries(['posts']);
      }
    }
    catch(error){
      // console.log(err)
      notification.error({
        message: error.response.data.message,
      });
      setAddReportModalShow(false)
      setPostToReport(null)
      setSelectedDisputeId(null)
    }
  }


  const disputes = [
    { id: 1, type: "Spam", description: "Unsolicited promotional content" },
    { id: 2, type: "Fraud", description: "Unauthorized transaction attempt" },
    { id: 3, type: "Phishing", description: "Attempt to steal personal information" },
    { id: 4, type: "Impersonation", description: "Pretending to be someone else" },
    { id: 5, type: "Scam", description: "Fraudulent scheme to obtain money" },
    { id: 6, type: "Harassment", description: "Bullying or threatening behavior" },
    { id: 7, type: "Copyright Violation", description: "Unauthorized use of copyrighted material" },
    { id: 8, type: "Malware", description: "Software designed to harm or exploit" },
  ];


  return (
    <div className="post">
      <div className="container">
        <div className="user">
          <div className="userInfo">
            <img src={post.userProfileImageUrl} alt="" />
            <div className="details">
              <Link
                to={`/profile/${post.userUUID ? post.userUUID : "1"}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <span className="name">{post.userName}</span>
              </Link>
              <span className="date">
                {post.postUploadedAt}
              </span>
            </div>
          </div>
          <MoreHorizIcon onClick={() => setMenuOpen(!menuOpen)} />
            {
              menuOpen &&  <div  className="moreOptions" style={{zIndex:'100'}}>
                {
                  post.userId !== currentUser.id &&
                  <>
                  {
                    !saved ?
                    <button className="more-button" onClick={() => handleSavePost(post.postId)}>save <BookmarkBorderIcon className="size-4"/></button>
                    :
                  <button className="more-button" onClick={() => handleDelSavedPost(post.postId)}>saved <BookmarkIcon className="size-4"/></button>
                  }
                  <button className="more-button" onClick={()=>{setAddReportModalShow(true);setPostToReport(post.postId)}}>Report <ErrorIcon className="size-4"/></button>
                  </>
                }
              {
              post.userId === currentUser.id && (
                  <div>
                    <button className="more-button" onClick={() => handleDelete(post.postId)} style={{backgroundColor:"red",color:"white"}}>Delete <DeleteIcon className="size-4"/></button>
                  </div>
                )
              }
            </div>
            }
        
          {/* {menuOpen && post.userId === currentUser.id && (
            <div>
              <button onClick={()=>handleDelete(post.postId)}>Delete</button>
            </div>
          )} */}
        </div>
        <div className="content">
          <p className="postName">{post.postName}</p>
          {renderMedia()}
        </div>
        <div className="info">
          <div className="item">
            {
              post.userReactStatus ? <FavoriteOutlinedIcon sx={{color:"red"}} onClick={handleRemoveLike}/> : <FavoriteBorderOutlinedIcon onClick={handleLike} />
            }
            
            {post.totalCountOFReact}
          </div>
          <div className="item" onClick={() => setCommentOpen(!commentOpen)}>
            <TextsmsOutlinedIcon />
            {commentOpen ? t("hideComments"): t("seeComments")}
          </div>
          {/* <div className="item">
            <ShareOutlinedIcon />
            Share
          </div> */}
        </div>
        {commentOpen && (
          <Comments
            postId={post.postId}
            setCommentOpen={setCommentOpen}
            commentOpen={commentOpen}
          />
        )}
      </div>
      <Modal
              open={addReportModalShow}
              onCancel={() => {
                setAddReportModalShow(false);
                // setPostToShow(null);
              }}
              footer={null}
            >
            <h1>REPORT</h1>
            <div>
              {
                disputes.map((dis)=>{
                  return(
                    <div style={{background:'#faf7f7',padding:"10px",margin:"3px",borderRadius:"3px",display:'flex',justifyContent:"space-between",alignItems:"center"}}>
                      <div>
                      <h3>{dis.type}</h3>
                      <h5>{dis.description}</h5>
                      </div>  
                      <div>
                        <input type="radio"   checked={selectedDisputeId?.id === dis.id} onChange={()=>setSelectedDisputeId(dis)}/>
                      </div>  
                    </div>
                  )
                })
              }
              <button style={{backgroundColor:'#012e6b',width:"100%",color:"white",border:"none",padding:"10px",marginTop:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={addReportToPost}>Report</button>
            </div>

            </Modal>
    </div>
  );
};

export default Post;
