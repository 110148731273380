// import React, { useState, useEffect } from 'react';
// import { getLinkPreview } from 'link-preview-js';

// const LinkPreviewComponent = ({ url }) => {
//   const [preview, setPreview] = useState(null);

//   useEffect(() => {
//     const fetchPreview = async () => {
//       try {
//         const previewData = await getLinkPreview(url);
//         setPreview(previewData);
//       } catch (error) {
//         console.error('Error fetching preview:', error);
//       }
//     };

//     if (url) {
//       fetchPreview();
//     }
//   }, [url]);

//   if (!preview) {
//     return <div>Loading preview...</div>;
//   }

//   return (
//     <div className="link-preview">
//       <a href={url} target="_blank" rel="noopener noreferrer">
//         <h3>{preview.title}</h3>
//         <p>{preview.description}</p>
//         <img src={preview.image} alt="Link preview" />
//       </a>
//     </div>
//   );
// };

// const makeLinksClickableWithPreview = (text) => {
//   const urlRegex = /(https?:\/\/[^\s]+)/g;

//   return text.split(urlRegex).map((part, index) => {
//     if (urlRegex.test(part)) {
//       return (
//         <div key={index} className="link-preview-container">
          
//           <a href={part} target="_blank" rel="noopener noreferrer">
//             {part}
//           </a>  
         
//           <LinkPreviewComponent url={part} />
//         </div>
//       );
//     }

//     return part;  
//   });
// };

// export default makeLinksClickableWithPreview;



import React, { useState, useEffect } from 'react';
import "./previewLink.scss"
import lnkIco from '../../assets/link.png';


// const fetchPreviewData = async (url) => {
//   const proxyUrl = 'https://cors-proxy.htmldriven.com/';

//   // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
//   // const proxyUrl = 'https://in-prod.asyncgw.teams.microsoft.com/urlp/v1/url/info?';
//   const targetUrl = url;

//   try {
//     const response = await fetch(proxyUrl + targetUrl, {
//       method: 'GET',
//       headers: {
//         'X-Requested-With': 'XMLHttpRequest',
//       },
//     });
//     console.log(response)

//     if (!response.ok) {
//       throw new Error('Failed to fetch preview data');
//     }

//     const data = await response.json();
//     console.log(data)
//     return data;
//   } catch (error) {
//     console.error('Error fetching preview data:', error);
//     throw error;
//   }
// };

// const fetchPreviewData = async ({post,url}) => {
  // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';  
  // const proxyUrl = 'https://in-prod.asyncgw.teams.microsoft.com/urlp/v1/url/info?';  
//   const proxyUrl = 'https://api.linkpreview.net/?q=';  
//   const targetUrl = url;  

//   try {    
//     // const response = await fetch(proxyUrl + targetUrl, {
//     //   method: 'GET',
//     //   headers: {
//     //     // 'X-Requested-With': 'XMLHttpRequest',
//     //     'X-Linkpreview-Api-Key': '4c7c5551083aecc3620cf1ae5d83a339'  
//     //   },
//     // });

//     const response = await axios.get(`${proxyUrl}${targetUrl}`,{
//       headers:{
//         'X-Linkpreview-Api-Key': '4c7c5551083aecc3620cf1ae5d83a339'
//       }      
//     })
//     // console.log(response)
//     return response?.data

//   } catch (error) {
//     console.error('Error fetching preview data:', error);
//     throw error;  
//   }
// }; 



const LinkPreviewComponent = ({post,url}) => {
//   const [preview, setPreview] = useState(null);
//   const [loading, setLoading] = useState(true); 
//   const [error, setError] = useState(null); 

//   useEffect(() => {
//     const fetchPreview = async () => {
//       setLoading(true);  
//       setError(null);  

//       try {        
//         const previewData = await fetchPreviewData(url);
//         setPreview(previewData);
//       } catch (err) {
//         console.error('Error fetching preview:', err);
//         setError('Failed to load preview');
//       } finally {
//         setLoading(false);  
//       }
//     };

//     if (url) {
//       fetchPreview();
//     }
//   }, [url]);

//   if (loading) {
//     return <div>Loading preview...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

  return (
    <div className="link-preview">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className='img-box'>
        <img src={post?.postImageURl !== "emptyPostImageUrl" ? post?.postImageURl : lnkIco} alt="Link" style={{color:"blue", margin:"0px", height:"300px"}}/>
        </div>
        
        <div className='dsc-box'>
          <h4>{post?.videoThumbnailUrl || ''}</h4>         
          <p>{post?.description?.slice(0, 100)}{post?.description?.length > 100 ? '...' : ''}</p>          
          <h5 style={{color:"blue"}}>{url}</h5>
        </div>  
      </a>
    </div>
  );
};





const makeLinksClickableWithPreview = (post) => {  
  const text = post?.postName;  
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.split(urlRegex).map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <div key={index} className="link-preview-container">          
          <a href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>          
          <LinkPreviewComponent post={post} url={part}/>
        </div>
      );
    }

    return part;  
  });
};

export default makeLinksClickableWithPreview;
