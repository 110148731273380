import "./advertisements.scss";
import Image from "../../../assets/img.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import AWS from 'aws-sdk';
import { notification } from 'antd';
import heic2any from "heic2any";
import share from '../../../assets/share.png'
import axios from "axios";
import imageCompression from 'browser-image-compression';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import SendIcon from "@mui/icons-material/Send";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const Share = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const [isUploading, setIsUploading] = useState(false);      
    const [districts, setDistricts] = useState([]);
    useEffect(() => {
        const fetchDistricts = async () => {
          try {
            const response = await axios.get(apiUrl + "/ssakti/users/district/getAllDistricts");
            // console.log(response.data.getAllDistrictData);
            setDistricts(response.data.getAllDistrictData);
          } catch (err) {
            console.error(err);
          }
        };
    
        fetchDistricts();
      }, [apiUrl]);

  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [dist,setDist] = useState()
  const S3_BUCKET = 'streesakti';
  const [inputNameError, setInputNameError] = useState("");

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion
  });

  const s3 = new AWS.S3();
  const { currentUser } = useContext(AuthContext);
  // console.log(currentUser.id)
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newAd) => {
      return makeRequest.post(`/ssakti/admin/posts/event/${dist}/${currentUser.id}`, newAd);
    },
    {
      onSuccess: () => {
        notification.success({
          message: 'Advertisement uploaded successfully',
          description: 'Your advertisement has been shared with everyone.',
        });
      },
    }
  );

  const handleInput = (e) => {
    
    setDesc(e.target.value);
    if (desc.length > 0) {
      setInputNameError("");
    }
  };

  const handleVideoCompression = async (videoFile) => {
  
      try {      
        const ffmpeg = new FFmpeg({ log: true});
  
        await ffmpeg.load();  
        await ffmpeg.writeFile(videoFile.name, new Uint8Array(await videoFile.arrayBuffer()));
     
        const outputFileName = videoFile.name.replace(/\.[^/.]+$/, "") + "_compressed.mp4";
  
        await ffmpeg.exec([
          '-i', videoFile.name,             
          '-vcodec', 'libx264',            
          '-crf', '35',                     
          '-preset', 'ultrafast',                               
          '-threads', '4',                 
          outputFileName                    
        ]);
       
        const compressedVideoData = await ffmpeg.readFile(outputFileName);     
        const compressedVideoBlob = new Blob([compressedVideoData.buffer], { type: 'video/mp4' });   
        const compressedVideo = new File([compressedVideoBlob], outputFileName, { type: 'video/mp4' });
    
        return compressedVideo;
    
      } catch (error) {
        console.log("Catch working called");
        console.error("Error during compression or upload:", error);
        throw error;
      }
    };

  const handleClick=async()=>{
      try {
        if (!file) {
              notification.error({
                message: 'No Image ',
                description: 'You have to add an image or a description.',
              });
              return;
        }
        else{
          if(file){
              let postPages;
            if (file.type.startsWith('image')) {
              const imageOptions = {
                maxSizeMB: 0.10 , // 150KB
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              };
              const compressedBlob = await imageCompression(file, imageOptions);
  
              // Convert the compressed Blob back to a File
              postPages = new File([compressedBlob], file.name, {
                type: compressedBlob.type,
                lastModified: file.lastModified,
              });              
            }else if (file.type.includes('video')) {
              const maxVideoSizeMB = 15;
              const videoSizeMB = file.size / 1024 / 1024;
              if (videoSizeMB > maxVideoSizeMB) {
                notification.error({
                  message: 'Video Too Large',
                  description: `The video size is ${videoSizeMB.toFixed(2)} MB, which exceeds the 15MB limit.`,
                });
                return;
              }
              const compressedVideo = await handleVideoCompression(file);
              postPages = compressedVideo;
            }
  
            const formData = new FormData();
            formData.append("postName", title);
            formData.append("advertisementDescription", desc);
            formData.append("selectFile", postPages);
            formData.append("postType", postPages.type);
            mutation.mutate(formData)
            setFile(null);
            setDesc('');
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  

  return (
    <div className="shareAds">
      <h1 style={{textAlign:'center'}}>Add advertisements</h1>
      <div className="container">
        <div className="top">
          <div className="left">
            <img src={currentUser.profilePic} alt="" />
            <div className="enter">
            <input placeholder="Title of the advertisements" value={title} name="title" onChange={(e)=>setTitle(e.target.value)}/>
            <textarea
            // resi
              rows={"3"}
              type="text"
              placeholder={`Describe your advertisement ${currentUser.name}?`}
              onChange={handleInput}
              value={desc}
            />
            </div>
          </div>
          <div className="right">
            {file && file.type.startsWith('image/') && (
              <img className="file" alt="" src={URL.createObjectURL(file)} />
            )}
            {file && file.type.startsWith('video/') && (
              <video className="file" controls>
                <source src={URL.createObjectURL(file)} type={file.type} />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
        {inputNameError && <span className="inputError">{inputNameError}</span>}
        <hr />
        <div className="bottom">
          <div className="left">
            <input
              type="file"
              id="file"
              accept="image/*,video/*"  // Accept only images and videos
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label htmlFor="file">
              <div className="item">
                <img src={Image} alt="" />
                <span>Add Image/Video</span>
              </div>
            </label>
          </div>
          <div className="center">
          <select
                  name="district"
                  value={dist}
                  onChange={(e)=>setDist(e.target.value)}
                >
                  <option value="">Select District</option>
                  {districts.map((district) => (
                    <option key={district.districtId} value={district.districtId}>
                      {district.districtName}
                    </option>
                  ))}
                </select>
          </div>
          <div className="right">
            {/* <button onClick={handleClick}>Share<img src={share} width={"18px"}/></button> */}
            <button onClick={handleClick} disabled={isUploading}>
                      {isUploading ? 
                      (<div className="loader">
                        <p>sharing</p>
                        <div className="spinner"></div>
                      </div>)
                      : "share" }
                      {!isUploading && <SendIcon style={{ fontSize: "1.3rem" }} />}
                    </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
