
import "./leftBar.scss";
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/Home";
import GroupIcon from '@mui/icons-material/Group';
import MessageIcon from '@mui/icons-material/Message';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import GroupsIcon from '@mui/icons-material/Groups';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import StoreIcon from '@mui/icons-material/Store';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import CreateIcon from '@mui/icons-material/Create';
import { DarkModeContext } from "../../context/darkModeContext";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { useTranslation } from "react-i18next"

const LeftBar = () => {

  const { toggle, darkMode } = useContext(DarkModeContext);
  const { currentUser } = useContext(AuthContext);
  // console.log("this is current user",currentUser.UUID)
  const location = useLocation();
  const isMessagesPage = location.pathname === '/messages';
  // const activePath = location.pathname.includes;

  let name = currentUser.name;
  let remianedname = name.slice(1)
  let firstletter = name.charAt(0).toUpperCase()
  let username = firstletter + remianedname

  let email = currentUser.email;
  let frontPart = email.slice(0,3);
  let lastPart = email.slice(-4);
  let emailWithMask = frontPart + "********" + lastPart;

  const { t } = useTranslation()

  return (
    <div className={`leftBar ${isMessagesPage ? "messagePage" : ""}`}>
      <div className="container">
        <div className={`menu ${isMessagesPage ? "messagePage" : ""}`}>
          <Link to={`/profile/${currentUser.UUID}`}>
            <div className="user">
              <img
                src={currentUser.profilePic}
                alt={currentUser.name}
              />
              <div className={isMessagesPage ? "messagePage" : ""}>
                <span style={{"fontWeight":600}}>{username}</span>
                <p>{emailWithMask}</p>
              </div>
            </div>
          </Link>
          <hr className="hr-top" />
          <div className={`item ${location.pathname== "/" ? "active" : ""}`} >
            <Link to="/" className={`item-link ${location.pathname== "/" ? "active" : ""}`}>
              {/* <img src={Home} alt="" /> */}
              <HomeOutlinedIcon className="icon" fontSize="medium" sx={{ color: !darkMode ? "#000":(location.pathname=="/")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("home")}</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/friends") ? "active" : ""}`} >
            <Link to="/friends" className={`item-link ${location.pathname.includes("/friends") ? "active" : ""}`}>
              {/* <img src={Friends} alt="" /> */}
              <GroupIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/friends")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("friends")}</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/messages") ? "active" : ""}`}>
            <Link to="/messages"  className={`item-link ${location.pathname.includes("/messages") ? "active" : ""}`}>
              {/* <img src={Messages} alt="" /> */}
              <MessageIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/messages")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("messages")}</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/saved") ? "active" : ""}`}>
            <Link to="/saved"  className={`item-link ${location.pathname.includes("/saved") ? "active" : ""}`}>
              {/* <img src={bookmarks} alt="" /> */}
              <BookmarkIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/saved")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("saved")}</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/groups") ? "active" : ""}`}>
            <Link to="/groups"  className={`item-link ${location.pathname.includes("/groups") ? "active" : ""}`}>
              {/* <img src={Groups} alt="" /> */}
              <GroupsIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/groups")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("groups")}</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/videos") ? "active" : ""}`}>
            <Link to="/videos"  className={`item-link ${location.pathname.includes("/videos") ? "active" : ""}`}>
              <CameraAltIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/videos")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("Reel")}</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/userPage") ? "active" : ""}`}>
            <Link to="/userPage"  className={`item-link ${location.pathname.includes("/userPage") ? "active" : ""}`}>
              {/* <img src={Watch} alt="" /> */}
              <CreateIcon  className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/userPage")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("yourPages")}</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/events") ? "active" : ""}`}>
            <Link to="/events"  className={`item-link ${location.pathname.includes("/events") ? "active" : ""}`}>
              {/* <img src={event} alt="" /> */}
              <CalendarMonthIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/events")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("events")}</span>
            </Link>
          </div>

          {/* <div className={`item ${location.pathname.includes("/courses") ? "active" : ""}`}>
            <Link to="/courses"  className={`item-link ${location.pathname.includes("/courses") ? "active" : ""}`}>
              <DesktopMacIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/courses")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>Courses</span>
            </Link>
          </div> */}

          <div className={`item ${location.pathname.includes("/marketplace") ? "active" : ""}`}>
            <Link to="/marketplace"  className={`item-link ${location.pathname.includes("/marketplace") ? "active" : ""}`}>
              {/* <img src={Shop} alt="" /> */}
              <StoreIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/marketplace")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("marketplace")}</span>
            </Link>
          </div>

          <div className={`item ${location.pathname.includes("/ads") ? "active" : ""}`}>
            <Link to="/ads"  className={`item-link ${location.pathname.includes("/ads") ? "active" : ""}`}>
              {/* <img src={Colab} alt="" /> */}
              <AdsClickIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/ads")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>{t("ads")}</span>
            </Link>
          </div>

          {/* <div className={`item ${location.pathname.includes("/collaborations") ? "active" : ""}`}>
            <Link to="/collaborations"  className={`item-link ${location.pathname.includes("/collaborations") ? "active" : ""}`}>
              <HeadsetMicIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/collaborations")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>Collaborations</span>
            </Link>
          </div> */}

          {/* <div className={`item ${location.pathname.includes("/live") ? "active" : ""}`}>
            <Link to="/live"  className={`item-link ${location.pathname.includes("/live") ? "active" : ""}`}>
              <LiveTvIcon className="icon" fontSize="medium" sx={{color: !darkMode ? "#000":(darkMode && location.pathname=="/live")?"#000":"#fff"}}/>
              <span className={isMessagesPage ? "messagePage" : ""}>Live</span>
            </Link>
          </div> */}

        </div>
        <hr />
        {/* <div className="menu">
          <span>Your shortcuts</span>
          <div className="item">
            <img src={Events} alt="" />
            <span>Events</span>
          </div>
          <div className="item">
            <img src={Gaming} alt="" />
            <span>Gaming</span>
          </div>
          <div className="item">
            <img src={Gallery} alt="" />
            <span>Gallery</span>
          </div>
          <div className="item">
            <img src={Videos} alt="" />
            <span>Videos</span>
          </div>
          <div className="item">
            <img src={Messages} alt="" />
            <span>Messages</span>
          </div>
        </div> */}
        {/* <hr /> */}
        {/* <div className="menu">
          <span>Others</span>
          <div className="item">
            <img src={Fund} alt="" />
            <span>Fundraiser</span>
          </div>
          <div className="item">
            <img src={Tutorials} alt="" />
            <span>Tutorials</span>
          </div>
          <div className="item">
            <img src={Courses} alt="" />
            <span>Courses</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default LeftBar;
