import React, { useEffect, useState, useRef, useContext } from "react";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { makeRequest } from "../../axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import './groupChatWindow.scss';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../context/authContext";
import { useQuery } from "@tanstack/react-query";
import { Modal } from "antd";
import { notification } from 'antd';
import AboutGroup from "./AboutGroup";
import { SelectFriends } from "./SelectFriends";

const apiUrl = process.env.REACT_APP_API_URL;

const GroupChatWindow = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const stompClientRef = useRef(null);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [isAtBottom, setIsAtBottom] = useState(true);  
  const lastMessageRef = useRef('');
  const location = useLocation();
  const { selectedGroup, currentUser } = location.state || {};
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [seletedModalComponent, setSeletedModalComponent] = useState(null);
  const [msgContent, setMsgContent] = useState('');

  useEffect(() => {
    if (!currentUser || !selectedGroup) return;

    setIsConnected(false);
    const socket = new SockJS(apiUrl + '/ws');
    const stompClient = Stomp.over(socket);
    stompClientRef.current = stompClient;

    let subscription;

    const attemptConnection = () => {
      stompClient.connect({}, () => {
        setIsConnected(true);
        const receiverId = selectedGroup.groupId;
                
        subscription = stompClient.subscribe(`/topic/group/${receiverId}`, (message) => {
          const parsedMessage = JSON.parse(message.body); 
          console.log(parsedMessage)         
          if (parsedMessage.lastMessageTimestamp !== lastMessageRef.current) {
              lastMessageRef.current = parsedMessage.lastMessageTimestamp; 
              setMessages(prevMessages => [...prevMessages, parsedMessage]); 
            }             
        });
      }, (error) => {
        console.error('Error connecting:', error);
        setIsConnected(false);
        setTimeout(attemptConnection, 5000);
      });
    };

    attemptConnection();   
    
    return () => {
      if (subscription) subscription.unsubscribe();
      if (stompClientRef.current && isConnected) {
        stompClientRef.current.disconnect();
      }
    };   
  }, [currentUser, selectedGroup]);

  const loadChatHistory = async () => {
    setIsLoading(true);
    const senderId = currentUser.id;
    const groupId = selectedGroup.groupId;

    if (senderId && groupId) {
      try {
        const response = await makeRequest.get(`/ssakti/users/chatmessage/history/group/${groupId}`, {
          params: { page, size: 10 },
        });

        if (response.status === 200) {
          const newMessages = response?.data?.details;       
               
          setMessages(prevMessages => [...newMessages, ...prevMessages]);          
        }
        
      } catch (error) {
        console.error("Error loading messages:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadChatHistory();
  }, [page]);

  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    const scrollTop = chatContainer.scrollTop;
    const scrollHeight = chatContainer.scrollHeight;
    const clientHeight = chatContainer.clientHeight;

    if (scrollTop === 0 && !isLoading) {
      setPage(prevPage => prevPage + 1); 
    }
    
    if (scrollTop + clientHeight < scrollHeight) {
      setIsAtBottom(false);  
    } else {
      setIsAtBottom(true);  
    }
  };

  useEffect(() => {
    if (isAtBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isAtBottom]);  

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== "" && isConnected) {
      const senderId = currentUser.id;
      const receiverId = selectedGroup.groupId;

      if (stompClientRef.current) {
        const message = {
          sender: { userId: senderId },
          group: { groupId: receiverId },
          content: inputMessage,          
        };

        stompClientRef.current.send('/app/chat', {}, JSON.stringify(message));
        setInputMessage("");      
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleModal = (component, msgContent) => {   
    setMsgContent(msgContent);
    setIsModalVisible(true);
    setSeletedModalComponent(component)
  }

  let content;
    switch (seletedModalComponent){
      case "selectFriends":
        content = <SelectFriends  setIsModalVisible={setIsModalVisible} msgContent={msgContent}/>
      break;      
    }

  return (
    <div className='message-container'>
      <div className="chat-window">
        <div
          className="chat-messages"
          ref={chatContainerRef}
          onScroll={handleScroll}
        >
          <div className="nameContainer">
            <ArrowBackIcon onClick={handleBack} />
            <div><h2><b>{selectedGroup.groupName}</b></h2></div>
          </div>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message?.lastMessageSenderId === currentUser.id ? "me" : "friend"}`}
            >
              <span className={`${message?.lastMessageSenderId === currentUser.id ? "my-msg" : "friends-msg"}`}>
                {message?.lastMessageSenderName}
              </span>
              <div className="msg-container">
                <span className={`${message?.lastMessageSenderId === currentUser.id ? "me" : "friend"}`}>
                  {message?.lastMessageContent}
                </span>
                <Tooltip title="Forword Message" arrow>
                  <ShortcutIcon className="fwd-ico" onClick={()=> handleModal("selectFriends", message?.lastMessageContent)}/>
                </Tooltip>               
              </div>
            </div>
          ))}
          <div ref={messagesEndRef}></div>
        </div>

        <div className="input-container">
          <input
            type="text"
            value={inputMessage}
            onChange={handleInputChange}
            placeholder={isConnected ? "Type Your Message" : "Connecting...."}
            disabled={!isConnected}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSendMessage();
              }
            }}
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
      <Modal
            visible={isModalVisible}
            // onOk={handleLogout}
            onCancel={() => setIsModalVisible(false)} 
            footer={null}
            className="modalStyle"
          >
         {content}
          </Modal>
    </div>
  );
};

export default GroupChatWindow;

