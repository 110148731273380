import React, { useContext, useEffect, useState } from "react";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { notification } from "antd";
import Select from "react-select";

import "./boostPost.scss";

function BoostPost({ postId, setIsModalVisible }) {
  const { currentUser } = useContext(AuthContext);
  const [districts, setDistricts] = useState([]);
  const [inputs, setInputs] = useState({
    startDate: "",
    startTime: "",
    districtIds: [],
    endDate: "",
    endTime: "",
    status: "ACTIVE",
  });
 
  const fetchDistricts = async () => {
    try {
      const response = await makeRequest.get(
        "/ssakti/users/district/getAllDistricts"
      );
      setDistricts(response.data.getAllDistrictData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDistricts();
  }, []);

  const handleInput = (selectedOptions) => {    
    setInputs({ ...inputs, districtIds: selectedOptions.map(option => option.value) });
  };

  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split("T")[0]; 
  const currentTimeString = currentDate
    .toTimeString()
    .split(" ")[0]
    .slice(0, 5);
  const minTime =
    inputs.startDate === currentDateString ? currentTimeString : "00:00";

  const boostsPost = async () => {
    if (
      inputs.startDate === currentDateString &&
      inputs.startTime < currentTimeString
    ) {
      notification.error({
        message: "You cannot select a past time.",
      });
      return;
    }

    if (
      !inputs.startDate ||
      !inputs.endDate ||
      !inputs.startTime ||
      !inputs.endTime
    ) {
      notification.error({
        message: "Please fill all required fields",
      });
      return;
    }

    try {
      const response = await makeRequest.post(
        `/ssakti/admin/boosts/boost-post?userId=${currentUser.id}&postId=${postId}`,
        inputs
      );

      if (response.data.status) {
        notification.success({
          message: "Post Boosted Successfully",
          description: "Selected post has been successfully boosted.",
        });
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error while Boosting post:", error);
      notification.error({
        message: "Submission Error",
        description: "There was an error while Boosting post.",
      });
    } finally {
      setIsModalVisible(false);
    }
  };

  const districtOptions = districts.map((district) => ({
    value: district.districtId,
    label: district.districtName,
  }));

  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isSelected } = props;

    return (
      <div ref={innerRef} {...innerProps} className="custom-option">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
          className="custom-checkbox"
        />
        <span>{data.label}</span>
      </div>
    );
  };

  return (
    <div className="boostPost">
      <h1>Boost a Post</h1>
      <div className="modalGroups">
        <div className="date-container">
          <div className="innerDate-container">
            <label className="labelForDate">Start Date</label>
            <input
              className="date-input"
              name="startDate"
              value={inputs.startDate}
              onChange={(e) => setInputs({ ...inputs, startDate: e.target.value })}
              type="date"
              placeholder="Date"
              min={currentDateString}
            />
          </div>
          <div className="innerDate-container">
            <label className="labelForDate">Start Time</label>
            <input
              className="date-input"
              name="startTime"
              value={inputs.startTime}
              onChange={(e) => setInputs({ ...inputs, startTime: e.target.value })}
              type="time"
              placeholder="Time"
              min={minTime}
            />
          </div>
        </div>
        <div className="date-container">
          <div className="innerDate-container">
            <label className="labelForDate">End Date</label>
            <input
              className="date-input"
              name="endDate"
              value={inputs.endDate}
              onChange={(e) => setInputs({ ...inputs, endDate: e.target.value })}
              type="date"
              placeholder="Date"
              min={inputs.startDate}
            />
          </div>
          <div className="innerDate-container">
            <label className="labelForDate">End Time</label>
            <input
              className="date-input"
              name="endTime"
              value={inputs.endTime}
              onChange={(e) => setInputs({ ...inputs, endTime: e.target.value })}
              type="time"
              placeholder="Time"
            />
          </div>
        </div>
        
        <Select
          className="selInp"
          name="districtIds"
          isMulti 
          value={districtOptions.filter((option) => inputs.districtIds.includes(option.value))}
          onChange={handleInput}
          options={districtOptions}
          components={{ Option: CustomOption }}
          // menuIsOpen 
        />

        <button onClick={boostsPost}>Boost Post</button>
      </div>
    </div>
  );
}

export default BoostPost;
