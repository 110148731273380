import React, { useEffect, useContext, useState, useRef } from "react";
import Post from "../post/Post";
import "./posts.scss";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import LoadingPost from "../loading/LoadingPost";
import CircularProgress from "@mui/material/CircularProgress";
import CardCarousel from "../ReelCarousel/CardCarousel";

const Posts = ({ userId, isTimeline }) => {
  const { currentUser } = useContext(AuthContext);
  const postKey = isTimeline ? userId : currentUser.id;  
  const countFalseRef = useRef(true);

  const fetchPosts = async ({ pageParam = 0 }) => {   
    const endpoint = isTimeline
      ? `/ssakti/users/user/userTimeline/${currentUser.UUID}/${
          userId == currentUser.UUID ? "sameUser" : userId
        }`
      : (countFalseRef.current ?`/ssakti/users/home/boostPost` :`/ssakti/users/home/home/v1/${currentUser.id}`)
    try {
      const response = await makeRequest.get(endpoint, {
        params: { cursor: pageParam, size: 6 },
      });
      if (response.status === 204) {
        countFalseRef.current = false; 
        return {
          data: [],
          homePostData: [],
          postsData: [],
          hasNextPage: false,
          nextPageNo: undefined,
          hasNextBoostPost:false,
        };
      }    
      countFalseRef.current = response?.data?.hasNextBoostPost || false;
      return response.data;
      
    } catch (error) {    
      console.error('Fetch error:', error);

      if(error.response?.data?.status === "Failed"){       
        countFalseRef.current = false; 
      }
      throw error;
    }
  };  

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching, isError } =
    useInfiniteQuery(["posts", postKey], fetchPosts, {
      getNextPageParam: (lastPage) => {
        
        return lastPage.hasNextPage || !lastPage?.hasNextBoostPost  ?( lastPage.hasNextPage?lastPage.nextCursor:0) : undefined;       
      },
      // retry:3,
      onError: (error) => {    
        console.error('Error fetching data:', error);  
        countFalseRef.current = false; 
      },
      refetchOnWindowFocus: false,
      staleTime: 600000,
      cacheTime: 600000,
    });

  // Detect scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop <
          document.documentElement.offsetHeight - 100 || 
        isFetching
      )
        return;

      if (hasNextPage) {
        fetchNextPage();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchNextPage, hasNextPage, isFetching]);

  // const count = data?.pages?.length || data?.length;
  const count = 1;
  return (
    <div className="posts">
      {isError ? (
        <div>No Posts!</div>
      ) : isLoading ? (
        <div className="fetching">
          <CircularProgress />
        </div>
      ) : data.pages.length === 0 || data?.length === 0 ? (
        <div>No Posts!</div>
      ) : count === 1 ? (
        <>
          {data.pages
            .flatMap((page) => page?.homePostData || page?.postsData)
            .map((post, index) => (
              <div key={post.postId}>
                <Post post={post} postKey={postKey} />
                {/* Show CardCarousel after the first 6 posts */}
                {index === 1 && <CardCarousel />}
              </div>
            ))}
        </>
      ) : (
        data.pages
          .flatMap((page) => page?.homePostData || page?.postsData)
          .map((post) => (
            <Post post={post} key={post.postId} postKey={postKey} />
          ))
      )}
    </div>
  );
};

export default Posts;
