import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { notification } from 'antd';
import { useQuery } from "@tanstack/react-query";
import './selectFriend.scss'

const apiUrl = process.env.REACT_APP_API_URL;

export const SelectFriends = ({setIsModalVisible, msgContent}) => {
    const { currentUser } = useContext(AuthContext);  
    const [selectedUser, setSelectedUser] = useState([]); 
    const id = currentUser.id;  
    const [isConnected, setIsConnected] = useState(false);
    const stompClientRef = useRef(null);  
  
    useEffect(() => {
      if (!currentUser ) return;
  
      setIsConnected(false);
      const socket = new SockJS(apiUrl + '/ws');
      const stompClient = Stomp.over(socket);
      stompClientRef.current = stompClient;
  
  
      const attemptConnection = () => {
        stompClient.connect({}, () => {
          setIsConnected(true);
         
        }, (error) => {
          console.error('Error connecting:', error);
          setIsConnected(false);
          setTimeout(attemptConnection, 5000);
        });
      };
  
      attemptConnection();   
      
      return () => {     
        if (stompClientRef.current && isConnected) {
          stompClientRef.current.disconnect();
        }
      };   
    }, [currentUser]);
    
    const fetchFriends = async (id) => {
      const response = await makeRequest.get(`/ssakti/users/friendrequest/getFriendsList/${id}`);
      return response.data;
    };
  
  
  
      const { data, isLoading, isError } = useQuery(
      ['friends', id],
      () => fetchFriends(id),{
        refetchOnWindowFocus:false,
        staleTime: 600000, 
        cacheTime: 600000,
      }
    );
  
    useEffect(() => {
      setSelectedUser([]);
    }, [data]);
    
    const selectedToForword = (userId) => {
      setSelectedUser((prev) => {
        if (prev.includes(userId)) {
          return prev.filter((Id) => Id !== userId);
        } else {
          return [...prev, userId];
        }
      });
    };
  
    const handleForwordMessage= () => {       
      if (msgContent.trim() === "") {
        notification.error({
          message: 'Please enter a message and ensure you are connected.',         
        });
        return;
      }    
      if (!Array.isArray(selectedUser) || selectedUser.length === 0) {
        notification.error({
          message: 'Please select at least one user.',          
        });      
        return;
      }
      const senderId = currentUser.id; 
      const receiverIds = selectedUser;  
      console.log(senderId, receiverIds,msgContent)
     
      if (stompClientRef.current) {

          const message = {
              sender: senderId,
              receiverIds: receiverIds.map(id => parseInt(id)),
              content: msgContent
          };

          stompClientRef.current.send('/app/forwardMessage', {}, JSON.stringify(message));         
 
          setSelectedUser([]);
          setIsModalVisible(false);
          notification.success({
            message: 'Message forwarded to all selected users!',
          });
      } else {
        console.log("STOMP client is not connected or invalid.");
        notification.error({
          message: 'Unable to send message. Please check your connection.',
        });  
      }
  };
  
      
  
    if (isError) {
      return <p>Error fetching Friends.</p>;
    }
  
    if (isLoading ) {
      return <p>Loading...</p>;
    }
  
    if (!data || !data.friendListData || data.totalFriend.length === 0) {
      return <p>No Friend found.</p>;
    }
  
    return (
      <div className="selectFriend">
        <h1 style={{ margin: "30px" }}>Forword Message</h1>
  
        <div className="list" >
        {data?.friendListData && data?.totalFriend > 0 ? (
          data.friendListData.map((item, index) => (
              <div key={index} className="friend" style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                  <img
                    src={item.userProfileImagePath}
                    alt={item.userFirstName}
                    width="30px"
                    height="30px"
                    style={{ borderRadius: "50px" }}
                  />
                  <p>{item.userFirstName} {item.userLastName}</p>
                </div>
                <input
                  type="checkbox"
                  checked={selectedUser.includes(item.userId)}
                  onChange={() => selectedToForword(item.userId)} 
                />
              </div>
            ))
          ) : (
            <div style={{ marginTop: "20px" }}>No pending friends to add</div>
          )}
        </div>
  
        <div style={{display:"flex", flexDirection:"row-reverse"}}>
          <button
            style={{
              backgroundColor: "#012e6b",
              color: "white",
              border: "none",
              padding: "10px",
              borderRadius: "5px",
              fontWeight: "700",
            }}
            onClick={handleForwordMessage}
          >
            Send Message
          </button>
        </div>  
        
  
        {/* <Pagination
          showSizeChanger
          onChange={onPageChange}
          current={currentPage + 1}
          pageSize={pageSize}
          total={data?.totalElements || 0}
          pageSizeOptions={[4, 8, 12, 16, 32, 64, 10000]}
          style={{ marginTop: "20px" }}
        /> */}
      </div>
    );
  };
  