import "./postManager.scss";
import { makeRequest } from "../../axios";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { Image, notification } from "antd";
import { Pagination } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";
import BoostPost from "./BoostPost";

// const { isLoading, isError, data: posts } = useQuery(['getPostsList'], () => {
//     return makeRequest.get('ssakti/admin/posts/getAllPosts').then((res) => {
//         return res.data.postDetails;
//     });
// })

const PostManager = () => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const location = useLocation();
  const userId = location?.state?.userId || null;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [seletedModalComponent, setSeletedModalComponent] = useState(null);

  const [postId, setPostId] = useState();

  const fetchPosts = async (page = 1, size = 6) => {
    const endpoint = userId
      ? `ssakti/admin/posts/getPosts/${userId}`
      : `ssakti/admin/posts/getAllPosts`;
    try {
      const response = await makeRequest.get(endpoint, {
        params: { page: page - 1, size },
      });

      if (response.status === 204) {
        return {
          pagesDetail: [],
          totalElements: 0,
        };
      }
      return response.data;
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  };

  const {
    data: posts,
    isLoading,
    isError,
  } = useQuery(
    ["getPostsList", currentPage, pageSize],
    () => fetchPosts(currentPage, pageSize),
    {
      keepPreviousData: true,
    }
  );

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const mutation = useMutation(
    (id) => {
      return makeRequest.delete("/ssakti/admin/posts/deletpost/" + id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getPostsList");
        notification.success({
          message: "Post Deleted successfully",
          description: "All the post and comments have been deleted",
        });
      },
      onError: () => {
        console.error("Failed to delete posts");
      },
      // onSettled: ()=>{
      //     queryClient.invalidateQueries('getPostsList');
      // }
    }
  );

  const deletePost = (postId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      mutation.mutate(postId);
    }
  };

  if (isError) {
    return <p>Error fetching posts</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const handleModal = (component, postId) => {
    setPostId(postId);
    setIsModalVisible(true);
    setSeletedModalComponent(component);
  };

  let content;

  switch (seletedModalComponent) {
    case "selectPost":
          content = <BoostPost setIsModalVisible={setIsModalVisible} postId={postId}/>
      break;
  }

  return (
    <div className="adminPostManager">
      <div className="post-list">
        <div className="heading-container">
          <div className="image-heading">
            <h3>User pic</h3>
          </div>

          <div className="info-heading">
            <h3 className="info-heading-common">Name</h3>
            <h3 className="info-heading-common">Email</h3>
            <h3 className="info-heading-common">Discription</h3>
            <h3 className="info-heading-common">Post</h3>
          </div>

          <div className="button-heading">
            <h3>Action</h3>
          </div>
        </div>
        {posts.details.map((post) => (
          <div key={post.id} className="post-card">
            <div className="image-container">
              <img src={post?.userProfileImagePath} />
            </div>

            <div className="post-info">
              <div className="name-container info-container-common">
                <h4>{post.userFirstName + " " + post?.userLastName}</h4>
              </div>
              <div className="email-container info-container-common">
                <p>{post.userEmail}</p>
              </div>
              <div className="totalPost-container info-container-common">
                <p>{post.postName}</p>
              </div>
              {/* <div className="date-container info-container-common"><p>{user.userCreatedAt}</p></div> */}
              <div className="totalPost-container info-container-common">
                <Image
                  src={
                    post?.postImageUrl?.includes("amazon")
                      ? "https://strishakti.org/uploads/strishakti/posts/posts" +
                        post.postImageUrl.split("/posts")[1]
                      : post?.postImageUrl
                  }
                />
              </div>
            </div>
            <div className="button-container">
              {/* <button onClick={() => deletePost(post.postId)}>Delete</button> */}
              <button
                className="boost-btn"
                onClick={() => handleModal("selectPost", post.postId)}
              >
                Boost Post
              </button>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        showSizeChanger
        onChange={onPageChange}
        current={currentPage}
        pageSize={pageSize}
        total={posts?.totalElements || 0}
        pageSizeOptions={[4, 8, 12, 16, 32, 64]}
      />

      <Modal
        visible={isModalVisible}
        // onOk={handleLogout}

        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className="modalStyle"
      >
        {content}
      </Modal>
    </div>
  );
};

export default PostManager;
