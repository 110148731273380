import React, { useContext, useEffect, useState, useRef } from "react";
import "./events.scss";
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";
import playIcon from "../../assets/Play-icon.png";
import { Spin } from "antd";
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';

function Ads() {
  const { currentUser } = useContext(AuthContext);
  const [allAds, setAllAds] = useState([]);
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);
  const [loadingVideoIndex, setLoadingVideoIndex] = useState(null);
  const [isMuted, setIsMuted] = useState(false); 
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRefs = useRef([]); 

  const getAllAdsByDistrict = async () => {
    try {
      const response = await makeRequest.get(
        `/sskati/users/posts/post/${currentUser.districtId}`
      );
      setAllAds(response.data.postData);
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  useEffect(() => {
    getAllAdsByDistrict();
  }, []); 

  const handlePlay = (index) => {
    setActiveVideoIndex(index);  
    setLoadingVideoIndex(index); 
  };

  const handleVideoCanPlay = (index) => {
    setLoadingVideoIndex(null); 
  };

  const togglePlayPause = () => {
    const video = videoRefs.current[activeVideoIndex];
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const toggleMute = () => {
    const video = videoRefs.current[activeVideoIndex];
    video.muted = !video.muted;
    setIsMuted(video.muted);
  };

  return (
    <div className="eventsPage">
      <div className="head">
        <h1>Advertisements in your district!</h1>
      </div>
      <div className="events">
        {allAds.map((ad, index) => (
          <div className="event" key={index}>
            <div className="image">
              {ad.postType === "video/mp4" ? (
                activeVideoIndex === index ? (
                  <div className="video-container">
                    <video
                      ref={(el) => (videoRefs.current[index] = el)} 
                      autoPlay
                      onCanPlay={() => handleVideoCanPlay(index)}
                      style={{ maxWidth: "24vh", borderRadius: "10px 10px 0 0" , maxHeight:"42vh"}}                    >
                      <source src={ad.postImageUrl} alt={ad.title} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>

                    {/* Custom Video Controls */}
                    {loadingVideoIndex === index ? (
                      <div className="spinner-overlay">
                        <Spin size="large" style={{height:"60px", width:"60px"}} className="custom-spinner"/>
                      </div>
                    ) : (
                      <div className="video-controls">
                        <button className="play-pause" onClick={togglePlayPause}>
                          {isPlaying ? <StopCircleOutlinedIcon sx={{color:"white"}}/>:<PlayCircleFilledWhiteOutlinedIcon sx={{color:"white"}}/>}
                        </button>
                        <button className="mute-unmute" onClick={toggleMute}>
                          {isMuted ? <VolumeOffOutlinedIcon sx={{color:"white"}}/>:<VolumeUpOutlinedIcon sx={{color:"white"}}/>}
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="thumbnail-container">
                    <img
                      src={ad.videoThumbnailUrl}
                      alt={ad.title}
                      className="thumbnail"
                      style={{ borderRadius: "10px 10px 0 0",maxWidth: "24vh",maxHeight:"42vh" }}
                    />
                    {loadingVideoIndex === index ? (
                      <div className="spinner-overlay">
                        <Spin size="large" />
                      </div>
                    ) : (
                      <img
                        src={playIcon}
                        alt="Play"
                        className="play-icon"
                        onClick={() => handlePlay(index)} 
                      />
                    )}
                  </div>
                )
              ) : (
                <img
                  src={ad.postImageUrl}
                  alt={ad.title}
                  style={{ borderRadius: "10px 10px 0 0" }}
                  width={"300px"}
                />
              )}
            </div>
            <div className="details" style={{ padding: "5px" }}>
              <h3>{ad.postName}</h3>
              <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                {ad.advertisementDescription}
              </p>
            </div>
          </div>
        ))}
        {!allAds.length && <p>No events found in your district.</p>}
      </div>
    </div>
  );
}

export default Ads;
