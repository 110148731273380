import React, { useState, useContext, useEffect, useRef } from "react";
import "./reelsHome.scss";
import Comments from "../../components/comments/Comments";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import addReel from "../../assets/addReel.png";
import { useNavigate } from "react-router-dom";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import MessageIcon from "@mui/icons-material/Message";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import { notification } from 'antd';

const ReelsHome = () => {
  const [commentOpen, setCommentOpen] = useState(false);
  const [allPosts, setAllPosts] = useState([]); 
  const [currentPostId, setCurrentPostId] = useState(null); 
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [isPlaying, setIsPlaying]= useState(true);
  const [volume, setVolume] = useState(true);
  const queryClient = useQueryClient();
  const videoRef = useRef(null)
  const postKey = currentUser.id;
  const userId = currentUser.id;

  const fetchReel = async ({ pageParam = 0 }) => {
    const endpoint = `ssakti/users/reels/getPosts/v1/${currentUser.id}`;
    try {
      const response = await makeRequest.get(endpoint, {
        params: { page: pageParam, size: 3 },
      });
      if (response.status === 204 || response.data.postsData.length === 0) {
        // return { data: [], hasNextPage: false };
        return {
          data: [],
          homePostData: [],
          postsData: [],
          hasNextPage: false,
          nextPageNo: undefined,
        };
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const { data, fetchNextPage, hasNextPage, isLoading, isError } =
    useInfiniteQuery(["reels", postKey], fetchReel, {
      getNextPageParam: (lastPage) =>
        lastPage.hasNextPage ? lastPage.nextPageNo : undefined,
      refetchOnWindowFocus: false,
      onSuccess: (newData) => {
        setAllPosts((prevPosts) => [
          // ...prevPosts,
          ...newData.pages.flatMap((page) => page.postsData),
        ]);
        
      },
    });

  useEffect(() => {
    if (allPosts.length > 0 && currentPostId === null) {
      setCurrentPostId(allPosts[0].postId);
    }
  }, [allPosts, currentPostId]);


  const currentPost = allPosts.find(post => post.postId === currentPostId);

  const handleNextClick = () => {
    if (currentPostId) {
      const currentIndex = allPosts.findIndex(post => post.postId === currentPostId);
      if (currentIndex < allPosts.length - 1) {
        setCurrentPostId(allPosts[currentIndex + 1].postId);
      } else if (hasNextPage) {
        fetchNextPage();
      }
    }
  };

  const handleBackClick = () => {
    if (currentPostId) {
      const currentIndex = allPosts.findIndex(post => post.postId === currentPostId);
      if (currentIndex > 0) {
        setCurrentPostId(allPosts[currentIndex - 1].postId);
      } else {
        setCurrentPostId(allPosts[allPosts.length - 1].postId);
      }
    }
  };

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
    const videoElement = videoRef.current; 
    if (videoElement) {
      if (isPlaying) {
        videoElement.pause();  
      } else {
        videoElement.play();  
      }
    }
  };
  
  const handleSound = () => {
    setVolume(!volume);
    const videoElement = videoRef.current;  
    if (videoElement) {
      videoElement.muted = !volume; 
    }
  };

  //Like on Reel
  const handleLike = () => {
      makeRequest
        .post(
          `/ssakti/users/postreact/addReactOnPost/${currentUser.id}/${currentPost.postId}`,
          {
            postReactName: "Like",
            postReactImageUrl: "string",
          }
        )
        .then(() => {      
          queryClient.setQueryData(['reels', userId], (oldData) => {
            if (!oldData) return oldData;
            
            const updatedPages = oldData.pages.map((page) => {
              return {
                ...page,                          
                homePostData: page?.homePostData ? page.homePostData.map((h) =>
                  h.postId === currentPost.postId
                    ? { 
                        ...h, 
                        totalCountOFReact: h.totalCountOFReact + 1, 
                        userReactStatus: true 
                      }
                    : h
                )
                :undefined,
  
                postsData: page?.postsData 
                ? page.postsData.map((p) =>
                    p.postId === currentPost.postId
                      ? {
                          ...p,
                          totalCountOFReact: p.totalCountOFReact + 1,
                          userReactStatus: true,
                        }
                      : p
                  )
                : undefined,
              };
            });

            setAllPosts((prevPosts) => [
              // ...prevPosts,
              ...updatedPages.flatMap((page) => page.postsData),
            ]);
    
            return {
              ...oldData,
              pages: updatedPages, 
              
            };
            
          });
  
        })
        .catch((error) => {
          console.error("Error liking the reel:", error);
        });
    };

  // RemoveLike from reel   
  const handleRemoveLike = () => {    
      makeRequest
        .delete(
          `/ssakti/users/postreact/deleteReactOnPost/${currentUser.id}/${currentPost.postId}`,
          {
            postReactName: "Like",
          }
        )
        .then(() => {      
          queryClient.setQueryData(['reels', userId], (oldData) => {
            if (!oldData) return oldData;
            
            const updatedPages = oldData.pages.map((page) => {
              return {
                ...page,                          
                homePostData: page?.homePostData ? page.homePostData.map((h) =>
                  h.postId === currentPost.postId
                    ? { 
                        ...h, 
                        totalCountOFReact: h.totalCountOFReact - 1, 
                        userReactStatus: false 
                      }
                    : h
                )
                :undefined,
  
                postsData: page?.postsData 
                ? page.postsData.map((p) =>
                    p.postId === currentPost.postId
                      ? {
                          ...p,
                          totalCountOFReact: p.totalCountOFReact - 1,
                          userReactStatus: false,
                        }
                      : p
                  )
                : undefined,
              };
            });

            setAllPosts((prevPosts) => [
              // ...prevPosts,
              ...updatedPages.flatMap((page) => page.postsData),
            ]);
    
            return {
              ...oldData,
              pages: updatedPages, 
            };
          });	
      
        })
        .catch((error) => {
          console.error("Error while dis-liking the post:", error);
        });
    };
   
  //save the reel in cache

  const handleSavePost = async (postId) => {
    try {
      const response = await makeRequest.post(`/ssakti/users/savepost/savePost/${currentUser.id}/${postId}`);
      
      if (response.status === 201 || response.status === 200) {   

        queryClient.setQueryData(['reels', userId], (oldData) => {
                  if (!oldData) return oldData;
                  const updatedPages = oldData.pages.map((page) => {
                    return {
                      ...page,                          
                      homePostData: page?.homePostData ? page.homePostData.map((h) =>
                        h.postId === currentPost.postId
                          ? { 
                              ...h,                        
                              postSaved: true 
                            }
                          : h
                      )
                      :undefined,
        
                      postsData: page?.postsData 
                      ? page.postsData.map((p) =>
                          p.postId === currentPost.postId
                            ? {
                                ...p,                        
                                postSaved: true,
                              }
                            : p
                        )
                      : undefined,
                    };
                  });

                  setAllPosts((prevPosts) => [
                    // ...prevPosts,
                    ...updatedPages.flatMap((page) => page.postsData),
                  ]);
          
                  return {
                    ...oldData,
                    pages: updatedPages, 
                  };
                });            

      } else {
        notification.error({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('Error saving reel:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while saving the reel';
      notification.error({
        message: errorMessage,
      });
    }
  };

  // Reel remove from saved

  const handleDelSavedPost = async (postId) => {
    try {
      const response = await makeRequest.delete(`/ssakti/users/savepost/deletSavedPost/${currentUser.id}/${postId}`);
      
      if (response.status === 201 || response.status === 200) {   

        queryClient.setQueryData(['reels', userId], (oldData) => {
                  if (!oldData) return oldData;
                  const updatedPages = oldData.pages.map((page) => {
                    return {
                      ...page,                          
                      homePostData: page?.homePostData ? page.homePostData.map((h) =>
                        h.postId === currentPost.postId
                          ? { 
                              ...h,                        
                              postSaved: false 
                            }
                          : h
                      )
                      :undefined,
        
                      postsData: page?.postsData 
                      ? page.postsData.map((p) =>
                          p.postId === currentPost.postId
                            ? {
                                ...p,                        
                                postSaved: false,
                              }
                            : p
                        )
                      : undefined,
                    };
                  });

                  setAllPosts((prevPosts) => [
                    // ...prevPosts,
                    ...updatedPages.flatMap((page) => page.postsData),
                  ]);   
                  return {
                    ...oldData,
                    pages: updatedPages, 
                  };
                });       
      } else {
        notification.error({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('Error remove saving reel:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while saving the reel';
      notification.error({
        message: errorMessage,
      });
    }
  };
  
  return (
    <div className="reels-home">
      <div className="input-container" onClick={() => navigate(`/videos`)}>
        <img src={addReel} alt="" style={{ height: "60px" }} />
        <span>Create New Reel</span>
      </div>

      <div className="video-rec-container">
        <div className="backBtn-container">
          <button
            className="back-btn"
            onClick={() => {
              setCommentOpen(false);
              handleBackClick();
            }}
          >
            <KeyboardDoubleArrowLeftIcon sx={{ fontSize: "50px", backgroundColor:"rgba(0, 0, 0, 0.07);", borderRadius:"50%", margin:"5px"}} />
          </button>
        </div>

        <div className="video-page">
          {currentPost && (            
            <video 
              ref={videoRef}
              key={currentPost.postId}
              autoPlay={isPlaying}
              muted={volume} 
              className="video"
            >
              <source src={currentPost?.postImageURl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
            <div className="user-info">
              <div style={{display:"flex", alignItems:"center", gap:"2px"}}>
                <img className="user-pic" src={currentPost?.userProfileImageUrl} alt={currentPost?.userName} />
                <span className="user-name">{currentPost?.userName}</span>
              </div>
              <div style={{display:"flex", gap:"5px"}}>
                <button onClick={()=>handlePlay()}> {isPlaying?  <StopCircleOutlinedIcon sx={{color:"white"}}/>:<PlayCircleFilledWhiteOutlinedIcon sx={{color:"white"}}/>}</button>
                <button onClick={()=>handleSound()}> {volume? <VolumeOffOutlinedIcon sx={{color:"white"}}/>:<VolumeUpOutlinedIcon sx={{color:"white"}}/>}</button>             
              </div>
              
            </div>
        </div>

        <div className="reaction-container">
          <button
            // className="nxt-btn"
            onClick={() => {
              setCommentOpen(false);
              handleNextClick();
            }}
          >
            <KeyboardDoubleArrowRightIcon sx={{ fontSize: "50px", backgroundColor:"rgba(0, 0, 0, 0.07);", borderRadius:"50%", margin:"5px" }} />
          </button>

          <div className="icons-container">
            <div className="icon">
              <button className="like-btn">               
                {
                  currentPost?.userReactStatus ? <ThumbUpIcon 
                  sx={{color:"red", backgroundColor:"rgba(0,0,0,0.07)", borderRadius:"50%", padding:"10px"}} 
                  onClick={handleRemoveLike}
                  /> : <ThumbUpOffAltIcon 
                  sx={{backgroundColor:"rgba(0,0,0,0.07)", borderRadius:"50%", padding:"10px"}}                  
                  onClick={handleLike} 
                  />
                }
              </button>
            </div>
            <div className="icon">
              <button
                className="comment-btn"
                onClick={() => setCommentOpen(!commentOpen)}
              >
                <MessageIcon sx={{backgroundColor:"rgba(0,0,0,0.07)", borderRadius:"50%", padding:"10px"}}/>
              </button>
            </div>
            <div className="icon">
              <button>
                {/* <BookmarkBorderIcon /> */}
                {
                  currentPost?.postSaved ? <BookmarkIcon
                   sx={{color:"Black", backgroundColor:"rgba(0,0,0,0.07)", borderRadius:"50%", padding:"10px"}}
                    onClick={()=>handleDelSavedPost(currentPost?.postId)}
                    /> : <BookmarkBorderIcon 
                    sx={{backgroundColor:"rgba(0,0,0,0.07)", borderRadius:"50%", padding:"10px"}}
                  onClick={()=>handleSavePost(currentPost?.postId)}
                   />
                }
              </button>
            </div>
          </div>
        </div>
      </div>

      {commentOpen && (
        <Comments
          postId={currentPost?.postId || "511"}
          setCommentOpen={setCommentOpen}
          commentOpen={commentOpen}
        />
      )}

      {!commentOpen && <div>Comments Appear Here</div>}
    </div>
  );
};

export default ReelsHome;
