
import React, { useState, useRef, useContext, useEffect } from "react";
import "./cardCarousel.scss";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";

const CardCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [commentOpen, setCommentOpen] = useState([]);
  const videoRefs = useRef([]);
  const { currentUser } = useContext(AuthContext);
  const postKey = currentUser.id;
  const navigate = useNavigate();
   
  
  const fetchReel = async ({ pageParam = 0 }) => {
    const endpoint = `ssakti/users/reels/getPosts/v1/${currentUser.id}`;
    try {
      const response = await makeRequest.get(endpoint, { params: { page: pageParam, size: 3 } });
      if (response.status === 204 || response.data.postsData.length === 0) {
        return { data: [], hasNextPage: false };
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
  } = useInfiniteQuery(['reels', postKey], fetchReel, {
    getNextPageParam: (lastPage) => lastPage.hasNextPage ? lastPage.nextPageNo : undefined,
    refetchOnWindowFocus: false, 
  });
  
  const goToNextCard = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (data?.pages.flatMap(page => page.postsData)?.length || 1));
  };

  const goToPreviousCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data?.pages.flatMap(page => page.postsData)?.length) % (data?.pages.flatMap(page => page.postsData)?.length || 1));
  };
  


  const visibleCards = [currentIndex - 1, currentIndex, currentIndex + 1].map(index => {
    const posts = data?.pages.flatMap(page => page.postsData);
    return (index + posts?.length) % (posts?.length || 1);
  });

  //Try for stop autoplay in background

  useEffect(() => {   
    visibleCards.forEach((index, i) => {
      const videoElement = videoRefs.current[i];
      if (videoElement) {
        if (i === 1) {          
          videoElement.play();
        } else {         
          videoElement.pause();
        }
      }
    });
  }, [visibleCards]);

  const handleCardClick = (postId) => {
    navigate(`/videos/reels`); 
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading posts. Please try again.</div>;

  const posts = data?.pages.flatMap(page => page.postsData);

 

  return (
    <div className="carousel-container">
      {visibleCards !=null &&<h3 style={{alignSelf:"self-start", marginLeft:"15%", marginTop:"5%"}}>Reels</h3>}
      <div className="carousel">
        {visibleCards.map((index, i) => {
          const card = posts?.[index];
          if (!card) return null;
          const isCenter = i === 1; 
          return (
            <div key={card.postId} className={`carousel-card ${isCenter ? "center" : ""} ${i === 0 ? "left" : i === 2 ? "right" : ""}`} 
            // onClick={i === 0 ? goToPreviousCard : i === 2 ? goToNextCard : null}
            onClick={isCenter ? () => handleCardClick(card.postId) : (i === 0 ? goToPreviousCard : i === 2 ? goToNextCard : null)}
            >
              <div className="card-content">
                <div className="card-header">
                 
                  <div className="user-info">
                    <img className="user-pic" src={card.userProfileImageUrl} alt={card.userName} />
                    <span className="user-name">{card.userName}</span>
                  </div>
                </div>
                <div className="card-video">
                  {isCenter ? <video ref={(el) => (videoRefs.current[i] = el)} className="reel-video" muted controls autoPlay>
                    <source src={card.postImageURl} type="video/mp4"/>
                    Your browser does not support the video tag.
                  </video>:
                  
                  <video ref={(el) => (videoRefs.current[i] = el)} className="reel-video" muted >
                    <source src={card.postImageURl} type="video/mp4"/>
                    Your browser does not support the video tag.
                  </video>}                 
                </div>
                
                
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardCarousel;


