import React from "react";
import "./privacyPolicy.scss"; 

const PrivacyPolicy = () => {
  return (
    <div className="terms-container">
      <h2>Privacy Policy</h2>

      <section className="terms-section">
        <h3>1. Introduction</h3>
        <p>Welcome to our platform. By using our services, you agree to our privacy practices. Please read this privacy policy carefully.</p>
      </section>

      <section className="terms-section">
        <h3>2. Information Collection</h3>
        <p>We collect personal information such as your name, email, and browsing behavior to provide you with a better experience.</p>
      </section>

      <section className="terms-section">
        <h3>3. Information Use</h3>
        <p>Your information helps us improve our services and provide personalized recommendations.</p>
      </section>

      <section className="terms-section">
        <h3>4. Data Sharing</h3>
        <p>We do not share your personal data with third parties except when required by law or to facilitate service delivery.</p>
      </section>

      <section className="terms-section">
        <h3>5. User Rights</h3>
        <p>You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.</p>
      </section>

      <section className="terms-section">
        <h3>6. Security</h3>
        <p>We implement various security measures to protect your personal data, but we cannot guarantee absolute security.</p>
      </section>

      <section className="terms-section">
        <h3>7. Changes to This Policy</h3>
        <p>We may update this privacy policy from time to time. Please review it periodically to stay informed.</p>
      </section>

      <section className="terms-section">
        <h3>8. Contact Us</h3>
        <p>If you have any questions or concerns about this privacy policy, please <a href="#">contact us</a>.</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
