import "./reels.scss";
import React, { useContext, useState } from "react";
import addReel from "../../assets/addReel.png";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { notification } from 'antd';
import SendIcon from '@mui/icons-material/Send';

import { useNavigate } from "react-router-dom";
const Reels = () => {
  const [video, setVideo] = useState(null);
  const [file,setFile]= useState(null);
  const [caption, setCaption] = useState("");
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const handleVideoChange = (e) => {
    setFile(e.target.files[0])
    const file = e.target.files[0];
    if (file) {
      setVideo(URL.createObjectURL(file));
      // setVideo(file);
    }
  };

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  // const handlePublish = () => {
  //   console.log(caption)
  //   alert("Reel published!");
  // };
  

  const mutation = useMutation(
    (formData) => {
      return makeRequest.post(`/ssakti/users/reels/addReel/${currentUser.id}`, formData);
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["posts"]);
        // queryClient.invalidateQueries(["lastestActivities"]);
        notification.success({
          message: 'Reel uploaded successfully',
          description: 'Your reel has been shared with everyone.',
        });
      },
      onSettled: () => {
        setIsUploading(false);
      }
    }
  );

  const handleVideoCompression = async (videoFile) => {
  
      try {      
        const ffmpeg = new FFmpeg({ log: true});
  
        await ffmpeg.load();  
        await ffmpeg.writeFile(videoFile.name, new Uint8Array(await videoFile.arrayBuffer()));
     
        const outputFileName = videoFile.name.replace(/\.[^/.]+$/, "") + "_compressed.mp4";
  
        await ffmpeg.exec([
          '-i', videoFile.name,             
          '-vcodec', 'libx264',            
          '-crf', '35',                     
          '-preset', 'ultrafast',                               
          '-threads', '4',                 
          outputFileName                    
        ]);
       
        const compressedVideoData = await ffmpeg.readFile(outputFileName);     
        const compressedVideoBlob = new Blob([compressedVideoData.buffer], { type: 'video/mp4' });   
        const compressedVideo = new File([compressedVideoBlob], outputFileName, { type: 'video/mp4' });
    
        return compressedVideo;
    
      } catch (error) {
        console.log("Catch working called");
        console.error("Error during compression or upload:", error);
        throw error;
      }
    };


  const handlePublish = async () => {
    if (!file) {
      notification.error({
        message: 'No Video ',
        description: 'You have to add an Video.',
      });
      return;
    }
    setIsUploading(true);
  
    let fileToUpload
  
    if (file) {
      try {
        if (file.type.includes('video')) {
          // Check if the video size exceeds 10MB
          const maxVideoSizeMB = 10;
          const videoSizeMB = file.size / 1024 / 1024;
          if (videoSizeMB > maxVideoSizeMB) {
            notification.error({
              message: 'Video Too Large',
              description: `The video size is ${videoSizeMB.toFixed(2)} MB, which exceeds the 10 MB limit.`,
            });
            setIsUploading(false);
            return;
          }
          const res = await handleVideoCompression(file);        
          
          fileToUpload = res;
        }
       
        // Upload the file
        const formData = new FormData();
        formData.append('postName', caption);
        formData.append('postImage', fileToUpload);
        formData.append('postType', file.type);
  
        mutation.mutate(formData);
      } catch (error) {
        console.log('Error during compression or upload:', error);
      } finally {
        setFile(null);
        setVideo(null);
        setCaption('');
        setIsUploading(false);
      }
    }
  };

  return (
    <div className="reels-container">
      <div className="left-section">
        <div className="top-site">
          <h5 style={{ color: "gray" }}>Create New Reel</h5>
          <h2>Add New Video</h2>
          {!video ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <input
                type="file"
                id="file"
                accept="video/*"
                style={{ display: "none" }}
                onChange={handleVideoChange}
              />
              <label htmlFor="file">
                <div className="input-container">
                  <img src={addReel} alt="" style={{ height: "60px" }} />
                  <span>Add Video</span>
                </div>
              </label>
            </div>
          ): (
            <>
              <button className="unselect-btn" onClick={()=>setVideo(null)}>Deselect</button>
            </>
          )         
          }

          {video && (
            <div className="video-preview">
              <video src={video} width="100%" autoPlay={false} />
            </div>
          )}
          <textarea
            placeholder="Add a caption..."
            value={caption}
            onChange={handleCaptionChange}
          />
        </div>

        <div style={{display:"flex", gap:"2px"}}>
        <button onClick={()=>navigate(`/videos/reels`)} >View Reels</button>
          {
            video ? (
              // <button onClick={handlePublish} >Publish Reel</button>

              <button onClick={handlePublish} disabled={isUploading}>
              {isUploading ? 
              (<div className="loader">
                <p>Publishing</p>
                <div className="spinner"></div>
              </div>)
              : "Publish"}
              {!isUploading && <SendIcon style={{ fontSize: "1.3rem" }} sx={{ color:"#fff" }} />}
            </button>
            ) : (
              <button disabled className="post-btn">Publish Reel</button>
            )
          }
          
        </div>
      </div>

      <div className="right-section">
        {/* <h3 style={{alignSelf:"flex-start", position:"absolute", top:"100px"}}>Preview</h3> */}
        {video ? (
          <>
            <div className="preview-container">
              <video src={video} controls width="100%" autoPlay={true} loop={true} />
            </div>
          </>
        ) : (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3>Your video preview</h3>
            <p>upload your video in order to see a preview here.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reels;
